const FORM_TYPES = [
  { value: 0, label: "Select From Type" },
  { value: 4503, label: "Integrated Intake Based on Funding Type" },
  { value: 4496, label: "Primary Sorting Intake" },
  { value: 4492, label: "Adult Behaviour Intake Form" },
  { value: 4490, label: "OAP Intake Form" },
  { value: 4493, label: "CW Child Intake Form" },
  { value: 4502, label: "TBI Intake - Modified BIST" },
  { value: 4499, label: "QABF [Standardized Test]" },
  { value: 4500, label: "FAST [Standardized Test]" },
  { value: 4501, label: "MAS [Standardized Test]" },
];

export default {
  FORM_TYPES,
};
